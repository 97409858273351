.a{
  text-decoration: none;
  color:rgb(255, 115, 0);
font-size: 1.3rem;
padding: 1%;
}

.byMe{
 
    text-align:center;

    margin:0 auto;
    color: white;
    font-size: 1rem;
    font-family: "RubikR";
}