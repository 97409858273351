.form{
    display: flex;
    width:100%;
flex-direction: column;
justify-content: center;
    margin:2% auto;
    
    }

    .logo{
        width: 30%;
        object-fit: contain;
        margin:5% auto;
    }
    .p{
        font-size: 2rem;
        font-family: "RubikR";
        color:white;
        text-align: center;
    }
    .title{
        font-size: 4rem;
        font-family: "Rubik";
 
   
   text-align: center;
  
      color:white;
    }

    .column{

        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .button{
        width:15%;
        display: flex;
    text-align: center;
        padding:1vw 4vw;
        font-family: "RubikR";
 justify-content: center;
 color:rgb(255, 255, 255);
        font-size: 18px;
        background: none;
        margin:2% auto;
        border-radius: 10px;
        border: 2px solid rgba(255, 130, 0, 1);
        cursor: pointer;

      }
      .button:hover{
        background-color:none;
        border: 2px solid rgb(255, 255, 255);
        transform: scale(1.1);
        color:white;
        transition: all ease-out 1s;
      }

    .image{
    
    display: flex;
    object-fit: cover;


    align-items: center;
    width:50%;

    }
    .center{


        display: flex;
        width:100%;
        margin:0 auto;
        justify-content: center;

          }

    .container{
        display: flex;
        width:100%;
        flex-direction: column;
 
     
       
      
        justify-content: center;

    }
    .input{
        width:20%;
        margin-top:2%;
        margin-bottom: 3%;
        padding:1vw 5vw;
       direction: rtl;
       margin: 2% auto;
        font-size: 20px;
        color:rgb(255, 255, 255);
        background: none;
        font-family: 'RubikR';
        border:1px solid white;
    
        border-radius: 10px;
  
      
    }
    .input:focus {
        background: none;
border:white;

    }
    .input::placeholder {
        color: white; /* Set the color of the placeholder text */
        font-family: 'RubikR';
    }

    @media only screen and (max-width: 350px) {

        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:5% auto 0;
            
            }
            .title{
                font-size: 2.5rem;
                font-family: 'Rubik';
                color:white;
              justify-content: center;
                width: 100%;
                margin-top:5%;
         
            }
            .logo{
                width: 70%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:20%;
                display: flex;
            text-align: center;
            padding :1.5vw 0 ;
                font-family: 'RubikR';
         justify-content: center;
                font-size: 14px;
                background: none;
                border-radius: 8px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color: none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
            height:100%;
            width:0%;
            margin: auto 0
        
            }
            .center{
                display: flex;
                width:100%;
                margin:4% auto;
                justify-content: center;
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:5%;
                margin-bottom: 3%;
                padding:2vw 3vw;
               direction: rtl;
                font-size: 16px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 50%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }
    }
    @media only screen and (min-width:350px) and (max-width: 450px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:5% auto 0 ;
            
            }
            .title{
                font-size: 2.8rem;
                font-family: 'Rubik';
                color:white;
                margin-top:5%;
                width: 100%;
        
            }
            .logo{
                width: 70%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.2rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:30%;
                display: flex;
            text-align: center;
       
                font-family: 'RubikR';
         justify-content: center;
                font-size: 16px;
                background: none;
                border-radius: 8px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
        
        
                display: flex;
                width:100%;
                margin:5% auto;
             
                justify-content: center;
        
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:4%;
                margin-bottom: 4%;
                padding:2vw 3vw;
               direction: rtl;
                font-size: 18px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 50%;
                border-radius: 5px;
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }
    }
    @media only screen and (min-width:450px) and (max-width: 550px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:5% auto;
            
            }
            .title{
                font-size: 3.2rem;
                font-family: 'Rubik';
                color:white;
                justify-content: center;
                width: 100%;
             
            }
            .logo{
                width: 60%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.3rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:30%;
                display: flex;
            text-align: center;
            padding :1.5vw 0 ;
                font-family: 'RubikR';
         justify-content: center;
                font-size: 16px;
                background: none;
                border-radius: 10px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
        
        
                display: flex;
                width:100%;
                margin:5% auto;
         
                justify-content: center;
        
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:4%;
                margin-bottom: 4%;
                padding:2vw 3vw;
               direction: rtl;
                font-size: 20px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 50%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }


    }
    @media only screen and (min-width:550px) and (max-width: 650px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:5% auto;
            
            }
            .title{
                font-size:3.5rem;
                font-family: 'Rubik';
                color:white;
                justify-content: center;
                width: 100%;
         
            }
            .logo{
                width: 50%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.3rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:30%;
                display: flex;
            text-align: center;
                padding :1.5vw 0 ;
                font-family: 'RubikR';
         justify-content: center;
                font-size: 18px;
                background: none;
                border-radius: 8px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
        
        
                display: flex;
                width:100%;
                margin:3% auto;
          
                justify-content: center;
        
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:3%;
                margin-bottom: 3%;
                padding:2vw 3vw;
               direction: rtl;
                font-size: 20px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 50%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }

    }
    @media only screen and (min-width:650px) and (max-width: 750px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:1% auto;
            
            }
            .title{
                font-size: 3.8rem;
                font-family: 'Rubik';
                color:white;
               
                width: 100%;
         justify-content: center;
            }
            .logo{
                width: 40%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.4rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:20%;
                display: flex;
            text-align: center;
            padding :1vw 0 ;
                font-family: 'RubikR';
         justify-content: center;
                font-size: 18px;
                background: none;
                border-radius: 10px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
        
        
                display: flex;
                width:100%;
                margin:3% auto;
                
                justify-content: center;
        
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:3%;
                margin-bottom: 3%;
                padding:1vw 3vw;
               direction: rtl;
                font-size: 22px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 35%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }

    }
    @media only screen and (min-width:750px) and (max-width: 950px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:2% auto;
            
            }
            .title{
                font-size: 3.8rem;
                font-family: 'Rubik';
                color:white;
            
                width: 100%;
              justify-content: center;
            }
            .logo{
                width: 50%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.4rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:30%;
                display: flex;
            text-align: center;
            padding :1vw 0;
                font-family: 'RubikR';
         justify-content: center;
                font-size: 18px;
                background: none;
                border-radius: 8px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
        
        
                display: flex;
                width:50%;
                margin:2% auto;
                margin-left: auto;
                justify-content: left;
        
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:3%;
                margin-bottom: 3%;
                padding:1vw 3vw;
               direction: rtl;
                font-size: 22px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 30%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }

    }
    @media only screen and (min-width:950px) and (max-width: 1250px) {
        .form{
            display: flex;
            width:100%;
        flex-direction: column;
            margin:2% auto;
            
            }
            .title{
                font-size: 3.8rem;
                font-family: 'Rubik';
                color:white;
          
                width: 100%;
             justify-content: center;
            }
            .logo{
                width: 30%;
                object-fit: contain;
                margin:5% auto;
            }
            .p{
                font-size: 1.4rem;
                font-family: "RubikR";
                color:white;
                text-align: center;
            }
            .column{
        
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .button{
                width:15%;
                display: flex;
            text-align: center;
       padding:0.85vw 0 ;
                font-family: 'RubikR';
         justify-content: center;
                font-size:18px;
                background: none;
                border-radius: 8px;
                border: 2px solid rgba(255, 130, 0, 1);
                cursor: pointer;
                margin: 0 auto;
                color:white;
              }
              .button:hover{
                background-color:  none;
                border: 2px solid rgb(255, 255, 255);
                transform: scale(1.1);
                transition: all ease-out 1s;
              }
        
            .image{
            
            display: flex;
            object-fit: cover;
        
        height:100%;

            width:0%;
            margin: auto 0
        
            }
            .center{
                display: flex;
                width:100%;
                margin-top:2%;
                justify-content: center;
                  }
        
            .container{
                display: flex;
                width:100%;
                flex-direction: column;
         
             
                justify-content: space-between;
                  
            }
            .input{
                margin-right:auto;
                margin-top:3%;
                margin-bottom: 3%;
                padding:1vw 3vw;
               direction: rtl;
                font-size: 22px;
                color:rgb(255, 255, 255);
                background: none;
                font-family: 'RubikR';
                border:1px solid white;
            width: 25%;
                border-radius: 5px;
          
              
            }
            .input:focus {
                background: none;
        border:white;
        
            }
            .input::placeholder {
                color: white; /* Set the color of the placeholder text */
                font-family: 'RubikR';
            }
    }