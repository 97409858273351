.row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 5% auto;
}
.title{
    font-size: 4rem;
    font-family: "Rubik";
    color: white;
    text-align: center;
}

@media only screen and (max-width: 350px) {

    .title{
        font-size: 2.5rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .title{
        font-size: 3rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .title{
        font-size: 3.2rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .title{
        font-size: 3.5rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 650px) and (max-width: 850px) {

    .title{
        font-size: 3.5rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 850px) and (max-width:1050px) {

    .title{
        font-size: 3.8rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}
@media only screen and (min-width: 1050px) and (max-width:1250px) {

    .title{
        font-size: 4rem;
        font-family: "Rubik";
        color: white;
        text-align: center;
    }
    

}