.container {
display: flex;
flex-direction: row;
height:80vh;
width:85%;
margin:0 auto;
  }
  .leftSide {
    display: flex;
    align-items: center;
    width:35%;
    position: relative;
  }

  .rightSide {
    display: flex;
    flex-direction: column;
    margin:auto 0;
   height:60%;
    justify-content: space-evenly;
    text-align: right;
    flex:2;

  }
  .buttons {
    display: flex;
   justify-content: right;
    width: 100%; /* Added to ensure buttons take full width */
  }
  .center{
    width: 60%;
    justify-content: center;
    margin:0 auto;

  }
  .logo{

    width:100%;
    object-fit: cover;
    margin:0 auto;
    justify-content: center;

  }
  .image {
    height: 70%;
    width: 100%;
    object-fit: contain;
    margin:auto 0;
  }
  .title {
    font-family: "Rubik";
    font-size: 4rem;
    color: white;
    width:90%;
    margin: 0 0 0 auto;
    text-align: right;
  }
  .slogen {
    font-family: "RubikR";
    font-size: 1.5rem;
    width: 70%;
    margin: 0 0 0 auto;
    text-align: right;
    color: white;
  }
  @media only screen and (max-width: 350px) {

    .container {
      display: flex;
      flex-direction: column-reverse;
      height:95vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:80%;
          margin:0 auto;

        }

        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto;
         height:60%;
          justify-content: space-evenly;
        
         
      
        }
        .buttons {
          display: flex;
         justify-content: center;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .center{
          width: 60%;
          justify-content: center;
          margin:0 auto;
      
        }
        .logo{
      
          width:100%;
          object-fit: cover;
          margin:0 auto;
          justify-content: center;
      
        }
        .image {
          height: 60%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 2.2rem;
          color: white;
          width:100%;
          margin: 0 auto;
          text-align: center;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1rem;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          color: white;
        }
  }
  @media only screen and (min-width: 350px) and (max-width: 450px) {

    .container {
      display: flex;
      flex-direction: column-reverse;
      height:95vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:60%;
          margin:0 auto;

        }
        .center{
          width: 50%;
          justify-content: center;
          margin:2% auto;
      
        }
        .logo{
      
          width:100%;
          object-fit: cover;
          margin:0 auto;
          justify-content: center;
      
        }

        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto;
         height:60%;
          justify-content: space-evenly;
        
         
      
        }
        .buttons {
          display: flex;
         justify-content: center;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 2.8rem;
          color: white;
          width:100%;
          margin: 0 auto;
          text-align: center;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.1rem;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          color: white;
        }
  }
  @media only screen and (min-width: 450px) and (max-width: 550px) {

    .container {
      display: flex;
      flex-direction: column-reverse;
      height:95vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:60%;
          margin:0 auto;

        }

        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto;
         height:50%;
          justify-content: space-evenly;
        
         
      
        }
        .buttons {
          display: flex;
         justify-content: center;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .center{
          width: 40%;
          justify-content: center;
          margin:4% auto;
      
        }
        .logo{
      
          width:100%;
          object-fit: cover;
          margin:0 auto;
          justify-content: center;
      
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 2.8rem;
          color: white;
          width:100%;
          margin: 0 auto;
          text-align: center;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.2rem;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          color: white;
        }
  }
  @media only screen and (min-width: 550px) and (max-width: 650px) {

    .container {
      display: flex;
      flex-direction: column-reverse;
      height:95vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:55%;
          margin:0 auto;

        }

        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto;
         height:60%;
          justify-content: space-evenly;
        
         
      
        }
        .buttons {
          display: flex;
         justify-content: center;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .center{
          width: 35%;
          justify-content: center;
          margin:4% auto;
      
        }
        .logo{
      
          width:100%;
          object-fit: cover;
          margin:0 auto;
          justify-content: center;
      
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 2.8rem;
          color: white;
          width:100%;
          margin: 0 auto;
          text-align: center;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.3rem;
          width: 90%;
          margin: 0 auto;
          text-align: center;
          color: white;
        }
  }
  @media only screen and (min-width: 650px) and (max-width: 850px) {
    .container {
      display: flex;
      flex-direction: row;
      height:80vh;
      width:90%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:35%;
          position: relative;
        }
      
        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto 0;
         height:70%;
          justify-content: space-evenly;
          text-align: right;
          flex:2;
      
        }
        .buttons {
          display: flex;
         justify-content: right;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 2.5rem;
          color: white;
          width:90%;
          margin: 0 0 0 auto;
          text-align: right;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.2rem;
          width: 80%;
          margin: 0 0 0 auto;
          text-align: right;
          color: white;
        }
   
  }
  @media only screen and (min-width: 850px) and (max-width: 1050px) {
    .container {
      display: flex;
      flex-direction: row;
      height:80vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:35%;
          position: relative;
        }
      
        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto 0;
         height:70%;
          justify-content: space-evenly;
          text-align: right;
          flex:2;
      
        }
        .buttons {
          display: flex;
         justify-content: right;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 3rem;
          color: white;
          width:90%;
          margin: 0 0 0 auto;
          text-align: right;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.3rem;
          width: 85%;
          margin: 0 0 0 auto;
          text-align: right;
          color: white;
        }
   
  }
  @media only screen and (min-width: 1050px) and (max-width: 1250px) {
    .container {
      display: flex;
      flex-direction: row;
      height:80vh;
      width:85%;
      margin:0 auto;
        }
        .leftSide {
          display: flex;
          align-items: center;
          width:40%;
        
        }
      
        .rightSide {
          display: flex;
          flex-direction: column;
          margin:auto 0;
         height:70%;
          justify-content: space-evenly;
          text-align: right;
          flex:2;
      
        }
        .buttons {
          display: flex;
         justify-content: right;
          width: 100%; /* Added to ensure buttons take full width */
        }
        .image {
          height: 70%;
          width: 100%;
          object-fit: contain;
          margin:auto 0;
        }
        .title {
          font-family: "Rubik";
          font-size: 3.4rem;
          color: white;
          width:90%;
          margin: 0 0 0 auto;
          text-align: right;
        }
        .slogen {
          font-family: "RubikR";
          font-size: 1.4rem;
          width: 80%;
          margin: 0 0 0 auto;
          text-align: right;
          color: white;
        }
   
  }