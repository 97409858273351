.background{
    background: none;
    width:100%;
    height:18vh;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.logo {
    height: 100%; 
    width:15%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:3%;

    z-index: 1;
  }
  .row{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
   margin-left:auto;
   z-index: 1;
 
  }
  .navLink{
    font-size: 1.5rem;
    font-family: "RubikR";
    cursor: pointer;
    z-index: 1;
    color:white;
    transition: all ease-out 1s;
  }
  .navLink:hover{
  
    cursor: pointer;
    transform:scale(1.1);

    z-index: 1;
    color:#ff4d00;
  }
  @media only screen and (max-width: 350px) {

  
    .logo{
        width:10rem;
        height: 8vh;
        object-fit: contain;
        display: flex;
        margin:5px auto;
          }
  
      /* background: linear-gradient(to left, #a69b97, #827773); */
      .navLink {
        margin: auto; /* Adjusted margin for spacing between links on small screens */
        font-size: 1.1rem;
        margin-right: 0.2rem;
        text-align: center;
        justify-content: center;
      }
      .row{
          height:12vh;
          width:100%;
          display: flex;
          margin:2% auto;

          justify-content: space-evenly;
      }
      .background{
  
  
          background: none;
          width:100%;
          height:12vh;
          display: flex;
          padding-top: 5%;
          flex-direction: column-reverse;
      }
  }
  @media only screen and (min-width: 350px)and (max-width:400px) {

    .logo{
      width:90%;
      height: 10vh;
      object-fit: cover;
      display: flex;
      opacity: 0.1;
      padding: auto;
        }

    /* background: linear-gradient(to left, #a69b97, #827773); */
    .navLink {
      margin: auto; /* Adjusted margin for spacing between links on small screens */
      font-size: 1.4rem;
      margin-right: 0.2rem;
      text-align: center;
      justify-content: center;
    }
    .row{
        height:12vh;
        width:100%;
        display: flex;
        margin:0 auto;
        justify-content: space-evenly;
    }
    .background{


        background: none;
        width:100%;
        height:12vh;
        display: flex;
        padding-top: 5%;
        flex-direction: column;
    }
  }
  @media only screen and (min-width: 400px)and (max-width:500px) {
   
    .logo{
        width:9rem;
        height: 6vh;
        object-fit: contain;
        display: flex;
        margin:5px auto;
          }
  
      /* background: linear-gradient(to left, #a69b97, #827773); */
      .navLink {
        margin: auto; /* Adjusted margin for spacing between links on small screens */
        font-size: 1.5rem;
        margin-right: 0.2rem;
        text-align: center;
        justify-content: center;
      }
      .row{
          height:12vh;
          width:100%;
          display: flex;
          margin:0 auto;
          justify-content: space-evenly;
      }
      .background{
  
  
          background: none;
          width:100%;
          height:12vh;
          display: flex;
          padding-top: 5%;
          flex-direction: column;
      }
  }
  @media only screen and (min-width: 500px)and (max-width:600px) {
   
    .logo{
        width:9rem;
        height: 7vh;
        object-fit: contain;
        display: flex;
        margin:5px auto;
          }
  
      /* background: linear-gradient(to left, #a69b97, #827773); */
      .navLink {
        margin: auto; /* Adjusted margin for spacing between links on small screens */
        font-size: 1.4rem;
        margin-right: 0.2rem;
        text-align: center;
        justify-content: center;
      }
      .row{
          height:12vh;
          width:100%;
          display: flex;
          margin:0 auto;
          justify-content: space-evenly;
      }
      .background{
  
  
          background: none;
          width:100%;
          height:14vh;
          padding-top: 5%;
          display: flex;
          flex-direction: column;
      }
  }
  @media only screen and (min-width: 600px)and (max-width:750px) {
    
    .logo{
        width:9rem;
        height: 8vh;
        object-fit: contain;
        display: flex;
        margin:5px auto;
        
          }
  
      /* background: linear-gradient(to left, #a69b97, #827773); */
      .navLink {
        margin: auto; /* Adjusted margin for spacing between links on small screens */
        font-size: 1.7rem;
        margin-right: 0.2rem;
        text-align: center;
        justify-content: center;
      }
      .row{
          height:12vh;
          width:100%;
          display: flex;
          margin:0 auto;
          justify-content: space-evenly;
      }
      .background{
  
  
          background: none;
          width:100%;
          height:14vh;
          display: flex;
          flex-direction: column;
        padding-top: 5%;
      }
  }
  @media only screen and (min-width: 750px)and (max-width:950px) {  
    .background{
      background: none;
      width:100%;
      height:18vh;
      display: flex;
      flex-direction: row;
      z-index: 1;
  }
  
  .logo {
      height: 100%; 
      width:20%;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left:3%;
  
      z-index: 1;
    }
    .row{
      width: 60%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
     margin-left:auto;
     z-index: 1;
   
    }
    .navLink{
      font-size: 1.4rem;
      font-family: "RubikR";
      cursor: pointer;
      z-index: 1;
      color:white;
      transition: all ease-out 1s;
    }
    .navLink:hover{
    
      cursor: pointer;
      transform:scale(1.1);
  
      z-index: 1;
      color:#ff4d00;
    }
  }
  @media only screen and (min-width: 950px)and (max-width:1150px) {  
    .background{
      background: none;
      width:100%;
      height:18vh;
      display: flex;
      flex-direction: row;
      z-index: 1;
  }
  
  .logo {
      height: 100%; 
      width:18%;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left:3%;
  
      z-index: 1;
    }
    .row{
      width: 60%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
     margin-left:auto;
     z-index: 1;
   
    }
    .navLink{
      font-size: 1.4rem;
      font-family: "RubikR";
      cursor: pointer;
      z-index: 1;
      color:white;
      transition: all ease-out 1s;
    }
    .navLink:hover{
    
      cursor: pointer;
      transform:scale(1.1);
  
      z-index: 1;
      color:#ff4d00;
    }
  }
