.container{
width: 250px;
height: 60px;
display: flex;
justify-content: center;
margin:2% auto
}
.button{
width:100%;
 border:none;
 background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
 border-radius: 15px;
 font-family: 'RubikR';
 color:black;
 font-size: 20px;
 text-decoration: none;
 box-shadow: 0 0 7px 1px rgb(4, 0, 52);
 cursor: pointer;
 text-align: center;

transition: all ease-out 1s;
 }
 .button:hover{
 
    transform: scale(1.1);
    background: linear-gradient(179deg, rgba(255, 179, 0, 0.686) 0%, rgba(255, 89, 0, 0.648) 100%);

border-radius: 15px;
color: rgb(255, 255, 255);
cursor: pointer;

}
 .button1{
width:100%;
border: 2px solid #ffe100;
background: none;
border-radius: 15px;
font-family: 'RubikR';
text-decoration: none;
font-size: 18px;
color:white;
cursor: pointer;
text-align: center;
}

 .button1:hover{
 
border: 2px solid rgb(255, 255, 255);
 background: none;
border-radius: 15px;
color: rgb(255, 255, 255);
cursor: pointer;

transform: scale(1.1);
transition: all ease-out 1s;
}
 @media only screen and (max-width: 320px) {
.container{

 width: 55vw;
 height: 10vw;
 display: flex;
 justify-content: center;
 margin:8% auto
 
 
 }
 .button{


width:100%;
color:black;
 
 background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
 border-radius: 5px;
 font-family: 'RubikR';
 font-size: 12px;
 text-decoration: none;
 cursor: pointer;
 text-align: center;
 }
 .button:hover{
 
 border: none;
 background: linear-gradient(179deg, rgba(255, 179, 0, 0.734) 0%, rgba(255, 89, 0, 0.805) 100%);
 border-radius: 10px;
 color: rgb(0, 0, 0);
 cursor: pointer;
 transform: scale(1.1);
 transition: all ease-out 1s;
 }

 }
 @media only screen and (min-width: 320px) and (max-width: 450px) {
.container{

 width: 50vw;
 height: 10vw;
 display: flex;
 justify-content: center;
 margin:8% auto
 
 
 }
 .button{
width:100%;
 
 background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
 border-radius: 5px;
 font-family: 'RubikR';
 text-decoration: none;
 font-size: 16px;
 cursor: pointer;
 color:black;
 text-align: center;
 }
 .button:hover{
 
 border: none;
 background: linear-gradient(179deg, rgba(255, 179, 0, 0.837) 0%, rgba(255, 89, 0, 0.782) 100%);
 border-radius: 10px;
 color: rgb(0, 0, 0);
 cursor: pointer;
 transform: scale(1.1);
 transition: all ease-out 1s;
 }

 }
 @media only screen and (min-width: 450px) and (max-width: 650px) {
.container{

 width: 35vw;
 height: 8vw;
 display: flex;
 justify-content: center;
 margin:4% auto
 
 
 }
 .button{


width:100%;

 
 background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
 border-radius: 5px;
 font-family: 'RubikR';
 font-size: 18px;
 color:black;
 text-decoration: none;
 cursor: pointer;
 text-align: center;
 }
 .button:hover{
 border: none;
 background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
 border-radius: 10px;
 color: rgb(0, 0, 0);
 cursor: pointer;
 transform: scale(1.1);
 transition: all ease-out 1s;
 }

 }
 @media only screen and (min-width: 650px) and (max-width: 1050px) {
.container{

width: 25vw;
height: 5vw;
display: flex;
justify-content: center;
margin:4% auto


}
.button{
 

width:100%;
color:black;

background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
border-radius: 5px;
font-family: 'RubikR';
font-size: 18px;
text-decoration: none;
cursor: pointer;
text-align: center;
}
.button:hover{

border: none;
background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
border-radius: 10px;
color: rgb(0, 0, 0);
cursor: pointer;
transform: scale(1.1);
transition: all ease-out 1s;
}

}
@media only screen and (min-width: 1050px) and (max-width: 1350px) {
.container{

width: 20vw;
height: 4vw;
display: flex;
justify-content: center;
margin:4% auto


}
.button{
 

width:100%;
color:black;

background: linear-gradient(179deg, rgba(255,180,0,1) 0%, rgba(255,91,0,1) 100%);
border-radius: 10px;
font-family: 'RubikR';
font-size: 18px;
text-decoration: none;
cursor: pointer;
text-align: center;
}
.button:hover{

border: none;
background: linear-gradient(179deg, rgba(255, 179, 0, 0.807) 0%, rgba(255, 89, 0, 0.818) 100%);
border-radius: 10px;
color: rgb(0, 0, 0);
cursor: pointer;
transform: scale(1.1);
transition: all ease-out 1s;
}

}